import React, { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import Sidebar from "../../components/Sidebar";
import {
  DeleteButton,
  Main,
  SubTitle,
  Table,
  TableActions,
  Tables,
  Title,
  FormBox,
  FormRow,
  SettingGroup,
  ContentInner,
} from "./settingsstore";

import { Content } from "../../utils/stylesbase";
import { API_URL } from "../../utils/env";

const SettingsStore = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [settings, setSettings] = useState([]);
  const [formsStatus, setFormsStatus] = useState(0);
  const [formData, setFormData] = useState({
    date: "",
    ticket: "",
    currency: "",
    authorizing: "",
    buyer: "",
    discount: "",
    platform: "",
    currentYear: "",
    paymentMethod: "",
  });

  const { sidebarClosed } = useContext(StoreContext);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    let body = {
      type: e.target[0].name,
      value: e.target[0].value,
    };

    fetch(`${API_URL}store/dropdown-data/create.php`, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          MySwal.fire({
            title: <p>Sucess</p>,
            html: <i>Your setting has been registered</i>,
            icon: "success",
          }).then(() => {
            return navigate(0);
          });
        }
      });
  }, []);

  const onSubmitCurrentYear = useCallback((e) => {
    e.preventDefault();

    fetch(`${API_URL}store/dropdown-data/update-current-year.php`, {
      method: "POST",
      body: JSON.stringify({
        value: formData.currentYear,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          MySwal.fire({
            title: <p>Sucess</p>,
            html: <i>Your setting has been updated</i>,
            icon: "success",
          }).then(() => {
            return navigate(0);
          });
        }
      });
  }, []);

  const deleteSetting = useCallback((settingId) => {
    MySwal.fire({
      title: "Are you sure you want to delete the option?",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${API_URL}store/dropdown-data/delete.php?id=${settingId}`, {
          method: "POST",
        })
          .then((response) => response.json())
          .then((response) =>
            Swal.fire("Setting removed successfully!!", "", "success").then(
              () => {
                return navigate(0);
              }
            )
          );
      }
    });
  }, []);

  const onChange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  useEffect(() => {
    fetch(`${API_URL}store/dropdown-data/list-all.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        setSettings(response);
        let newFormData = formData;
        newFormData.currentYear = response.find(
          (setting) => setting.type === "currentYear"
        ).value;
        setFormData(newFormData);
      });
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <Title>Settings - Store</Title>
        <ContentInner>
          <SettingGroup>
            <SubTitle>Current Year</SubTitle>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmitCurrentYear}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="currentYear"
                  label="Current Year"
                  variant="outlined"
                  name="currentYear"
                  onChange={onChange}
                  value={formData.currentYear}
                  type="number"
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Products</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "ticket")
                    .map((ticket) => {
                      return (
                        <tr key={ticket.id}>
                          <td>{ticket.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "ticket")
                    .map((ticket) => {
                      return (
                        <tr key={ticket.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(ticket.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newTicket"
                  label="New Product"
                  variant="outlined"
                  name="ticket"
                  onChange={onChange}
                  value={formData.ticket}
                  type="ticket"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Currency</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "currency")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "currency")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(data.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newCurrency"
                  label="New Currency"
                  variant="outlined"
                  name="currency"
                  onChange={onChange}
                  value={formData.currency}
                  type="text"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Authorizing</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "authorizing")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "authorizing")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(data.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newAuthorizing"
                  label="New Authorizing"
                  variant="outlined"
                  name="authorizing"
                  onChange={onChange}
                  value={formData.authorizing}
                  type="text"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Buyer</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "buyer")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "buyer")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(data.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newBuyer"
                  label="New Buyer"
                  variant="outlined"
                  name="buyer"
                  onChange={onChange}
                  value={formData.buyer}
                  type="text"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Discount</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "discount")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "discount")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(data.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newDiscount"
                  label="New Discount"
                  variant="outlined"
                  name="discount"
                  onChange={onChange}
                  value={formData.discount}
                  type="text"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Plataform</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "plataform")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "plataform")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(data.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newPlataform"
                  label="New Plataform"
                  variant="outlined"
                  name="plataform"
                  onChange={onChange}
                  value={formData.plataform}
                  type="text"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>

          <SettingGroup>
            <SubTitle>Payment Method</SubTitle>
            <Tables>
              <Table>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "paymentMethod")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>{data.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <TableActions>
                <tbody>
                  {settings
                    .filter((setting) => setting.type === "paymentMethod")
                    .map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            <DeleteButton
                              onClick={() => deleteSetting(data.id)}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </TableActions>
            </Tables>
            <FormBox
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
            >
              <FormRow>
                <TextField
                  id="newPaymentMethod"
                  label="New PaymentMethod"
                  variant="outlined"
                  name="paymentMethod"
                  onChange={onChange}
                  value={formData.paymentMethod}
                  type="text"
                  placeholder=""
                />
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>
        </ContentInner>
      </Content>
    </Main>
  );
};

export default SettingsStore;
