import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import Sidebar from "../../components/Sidebar";
import { Main, SubTitle, Table, Tables, Title, SubTr } from "./summary";

import { Content, DownloadExcelWrapper } from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import { Button } from "@mui/material";
import { API_URL } from "../../utils/env";

const Summary = () => {
  const [tickets, setTickets] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [reset, setReset] = useState(0);

  const tableRef = useRef(null);

  const { sidebarClosed, setSidebarClosed } = useContext(StoreContext);

  const toggleSelectedLine = useCallback(
    (tourId) => {
      let newSelectedLines = selectedLines;
      if (newSelectedLines.indexOf(tourId) === -1)
        newSelectedLines.push(tourId);
      else newSelectedLines.splice(newSelectedLines.indexOf(tourId), 1);
      setSelectedLines(newSelectedLines);
      setReset(Math.random());
    },
    [selectedLines]
  );

  useEffect(() => {
    setSidebarClosed(true);
    fetch(`${API_URL}tickets/summary.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedTickets = [];
        transformedTickets.push({ sector: "total" });
        response.forEach((ticket) => {
          if (
            (!ticket.sector && !ticket.leftSector) ||
            (!ticket.date && !ticket.leftDate)
          )
            return;
          let sectorAlreadyInserted = transformedTickets.find(
            (item) =>
              item.sector === ticket.sector || item.sector === ticket.leftSector
          );
          if (!sectorAlreadyInserted) {
            let newItem = {};
            newItem["sector"] = ticket.sector || ticket.leftSector;
            let ticketDate = ticket.date || ticket.leftDate;
            newItem[ticketDate] = {
              bought: parseInt(ticket.bought_quantity || 0),
              sold: parseInt(ticket.sold_quantity - ticket.nonPaid || 0),
              nonPaid: parseInt(ticket.nonPaid || 0),
              inventory:
                parseInt(ticket.bought_quantity || 0) -
                parseInt(ticket.sold_quantity || 0),
            };
            transformedTickets.push(newItem);
          } else {
            let ticketDate = ticket.date || ticket.leftDate;
            sectorAlreadyInserted[ticketDate] = {
              bought: parseInt(ticket.bought_quantity || 0),
              sold: parseInt(ticket.sold_quantity - ticket.nonPaid || 0),
              nonPaid: parseInt(ticket.nonPaid || 0),
              inventory:
                parseInt(ticket.bought_quantity || 0) -
                parseInt(ticket.sold_quantity || 0),
            };
          }

          let ticketDate = ticket.date || ticket.leftDate;

          let totalSector = transformedTickets.find(
            (item) => item.sector === "total"
          );

          if (totalSector[ticketDate]) {
            totalSector[ticketDate].bought += parseInt(
              ticket.bought_quantity || 0
            );
            totalSector[ticketDate].sold += parseInt(
              ticket.sold_quantity - ticket.nonPaid || 0
            );
            totalSector[ticketDate].nonPaid += parseInt(ticket.nonPaid || 0);
            totalSector[ticketDate].inventory +=
              parseInt(ticket.bought_quantity || 0) -
              parseInt(ticket.sold_quantity || 0);
          } else {
            totalSector[ticketDate] = {
              bought: parseInt(ticket.bought_quantity || 0),
              sold: parseInt(ticket.sold_quantity - ticket.nonPaid || 0),
              nonPaid: parseInt(ticket.nonPaid || 0),
              inventory:
                parseInt(ticket.bought_quantity || 0) -
                parseInt(ticket.sold_quantity || 0),
            };
          }
        });

        transformedTickets = transformedTickets.sort((a, b) =>
          a.sector === "total" ? 1 : -1
        );

        transformedTickets.forEach((ticket) => {
          ticket.total = {};
          Object.entries(ticket).forEach((date) => {
            if (date[0] !== "sector" && date[0] !== "total") {
              if (ticket.total.bought !== undefined) {
                ticket.total.bought += date[1].bought;
                ticket.total.sold += date[1].sold;
                ticket.total.nonPaid += date[1].nonPaid;
                ticket.total.inventory += date[1].inventory;
              } else {
                ticket.total.bought = date[1].bought;
                ticket.total.sold = date[1].sold;
                ticket.total.nonPaid = date[1].nonPaid;
                ticket.total.inventory = date[1].inventory;
              }
            }
          });
        });

        let orderedTickets = transformedTickets.sort((a, b) => {
          if (a.sector === "total") return 2;
          if (!a.sector.includes("Sector")) return 1;
          if (a.sector.includes("Sector") && !b.sector.includes("Sector")) {
            return -1;
          } else if (
            a.sector.includes("Sector") &&
            !a.sector.includes("Frisa") &&
            b.sector.includes("Sector") &&
            !b.sector.includes("Frisa")
          ) {
            let sectorA = parseInt(a.sector.replace("Sector", ""));
            let sectorB = parseInt(b.sector.replace("Sector", ""));
            return sectorA - sectorB;
          } else if (a.sector.includes("Frisa") && b.sector.includes("Frisa")) {
            let sectorA = a.sector.split(" ");
            sectorA = parseInt(sectorA[sectorA.length - 1]);
            let sectorB = b.sector.split(" ");
            sectorB = parseInt(sectorB[sectorB.length - 1]);
            if (sectorA === sectorB) {
              return a.sector.split(" ")[1].split("")[0] >
                b.sector.split(" ")[1].split("")[0]
                ? 1
                : -1;
            }
            return sectorA - sectorB;
          } else if (a.sector.includes("Frisa")) return 1;
          else if (b.sector.includes("Frisa")) return -1;
        });

        setTickets(orderedTickets);
      });

    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a,b) => {
          a = a.value;
          b = b.value;
          let dateA = new Date(`${a.split("/")[2]}-${a.split("/")[1]}-${a.split("/")[0]}`);
          let dateB = new Date(`${b.split("/")[2]}-${b.split("/")[1]}-${b.split("/")[0]}`);
          return dateA.getTime() - dateB.getTime()
        }).forEach((date) => transformedData.push(date.value));
        transformedData.push("total");
        setDates(transformedData);
      });
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Tickets</SubTitle>
        <Title>Summary</Title>
        <DownloadExcelWrapper>
          <DownloadTableExcel
            filename="Summary"
            sheet="summary"
            currentTableRef={tableRef.current}
          >
            <Button variant="outlined" style={{ marginBottom: "20px" }}>
              {" "}
              Export to excel{" "}
            </Button>
          </DownloadTableExcel>
        </DownloadExcelWrapper>
        <Tables>
          <Table ref={tableRef}>
            <thead>
              <tr>
                <th>Ticket</th>
                {dates.map((date) => (
                  <th key={date} colSpan="4">
                    {date}
                  </th>
                ))}
              </tr>
              <SubTr>
                <th></th>
                {dates.map((date) => (
                  <>
                    <th key={`${date}-BG`} className="titles">
                      BOUGHT
                    </th>
                    <th key={`${date}-SD`} className="titles">
                      SOLD
                    </th>
                    <th key={`${date}-NP`} className="titles">
                      NO PAID
                    </th>
                    <th key={`${date}-IV`} className="titles">
                      TOTAL
                    </th>
                  </>
                ))}
              </SubTr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <SubTr
                    key={`${ticket.sector}-${reset}`}
                    bigFont
                    total={ticket.sector === "total" ? "true" : "false"}
                    className={`${
                      selectedLines.indexOf(ticket.sector) !== -1
                        ? "selected"
                        : ""
                    } `}
                    onClick={() => toggleSelectedLine(ticket.sector)}
                  >
                    <td className="sector">{ticket.sector}</td>
                    {dates.map((date) => {
                      return (
                        <>
                          <td key={`${date}-BG-Value`}>
                            {ticket[date] && ticket[date].bought}
                          </td>
                          <td key={`${date}-SD-Value`}>
                            {ticket[date] && ticket[date].sold}
                          </td>
                          <td key={`${date}-NP-Value`}>
                            {ticket[date] && ticket[date].nonPaid}
                          </td>
                          <td
                            key={`${date}-IV-Value`}
                            style={{
                              color:
                                ticket[date] && ticket[date].inventory < 0
                                  ? "red"
                                  : "",
                            }}
                          >
                            {ticket[date] && ticket[date].inventory}
                          </td>
                        </>
                      );
                    })}
                  </SubTr>
                );
              })}
            </tbody>
          </Table>
        </Tables>
      </Content>
    </Main>
  );
};

export default Summary;
