import styled from "styled-components";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";

const Main = styled.div`
  background: #fcfcfc;
  position: relative;
  width: 100%;
  display: flex;
  min-height: 100vh;
`;

const Content = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 450px);
`;

const SubTitle = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #728095;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
`;

const Table = styled.table`
  white-space: nowrap;
  display: table-caption;
  text-align: center;
  border-spacing: 0;
  border-radius: 5px;
  border: solid 1px #ccc;
  overflow-x: scroll;
  max-height: 75vh;

  thead {
    background: #e6e6e6;
    height: 50px;
  }

  thead th {
    border-bottom: solid 1px #ccc;
    cursor: pointer;
    position: sticky;
    top: 0;
    background: #e6e6e6;
  }

  thead th.active {
    background: #f4f46a;
  }

  tr {
    height: 35px;
  }

  tr:not(:last-child) td {
    border-bottom: solid 1px #ccc;
  }

  tr.selected {
    td {
      background: #e4f681;
    }
  }

  th {
    padding: 10px 20px;
  }

  td {
    padding: 0 5px;
  }

  td:nth-child(2n + 1) {
    background: #e6e6e6;
  }
`;

const TableActions = styled.table`
  border-spacing: 0;
  th {
    display: flex;
    align-items: center;
  }

  thead {
    display: flex;
    align-items: center;
    height: 50px;
  }

  tr {
    display: flex;
    align-items: center;
    height: 35px;
  }

  td {
    display: flex;
    align-items: center;
  }
`;

const Tables = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
`;

const DeleteButton = styled(DeleteTwoToneIcon)`
  background: #d55b5b;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
`;

const EditButton = styled(TuneTwoToneIcon)`
  background: #f4f46a;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 5px !important;
  }
`;

export {
  Main,
  SubTitle,
  Content,
  Title,
  Table,
  TableActions,
  Tables,
  DeleteButton,
  EditButton,
  InnerContent,
};
