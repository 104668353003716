import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Context from "./Context";
import useStorage from "../../utils/useStorage";
import { API_URL } from "../../utils/env";

const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage("token");
  const [section, setSection] = useStorage("section");
  const [userPermissions, setUserPermissions] = useState("");
  const [userName, setUserName] = useState("");
  const [userUsername, setUserUsername] = useState("");
  const [currentYear, setCurrentYear] = useState(0);
  const [sidebarClosed, setSidebarClosed] = useState(
    !!sessionStorage.getItem("sidebar-closed")
  );
  const [selectedFiltersOrders, setSelectedFiltersOrders] = useState([]);
  const [ordersIsFiltered, setOrdersIsFiltered] = useState(false);
  const [filteredDatesOrders, setFilteredDatesOrders] = useState(
    localStorage.getItem("filteredDatesOrders")
      ? JSON.parse(localStorage.getItem("filteredDatesOrders"))
      : [null]
  );

  const [selectedFiltersInventorySummary, setSelectedFiltersInventorySummary] =
    useState([]);
  const [inventorySummaryIsFiltered, setInventorySummaryIsFiltered] =
    useState(false);

  const [
    selectedFiltersInventorySummaryFloripa,
    setSelectedFiltersInventorySummaryFloripa,
  ] = useState([]);
  const [
    inventorySummaryIsFilteredFloripa,
    setInventorySummaryIsFilteredFloripa,
  ] = useState(false);

  const [selectedFiltersInventoryDetails, setSelectedFiltersInventoryDetails] =
    useState([]);
  const [inventoryDetailsIsFiltered, setInventoryDetailsIsFiltered] =
    useState(false);

  const [
    selectedFiltersInventoryDetailsFloripa,
    setSelectedFiltersInventoryDetailsFloripa,
  ] = useState([]);
  const [
    inventoryDetailsIsFilteredFloripa,
    setInventoryDetailsIsFilteredFloripa,
  ] = useState(false);

  const [
    selectedFiltersInventorySummaryStore,
    setSelectedFiltersInventorySummaryStore,
  ] = useState([]);
  const [inventorySummaryIsFilteredStore, setInventorySummaryIsFilteredStore] =
    useState(false);

  const [
    selectedFiltersInventoryDetailsStore,
    setSelectedFiltersInventoryDetailsStore,
  ] = useState([]);
  const [inventoryDetailsIsFilteredStore, setInventoryDetailsIsFilteredStore] =
    useState(false);

  const navigate = useNavigate();

  const toggleSidebar = useCallback(() => {
    setSidebarClosed(!sidebarClosed);
    if (sidebarClosed) {
      sessionStorage.removeItem("sidebar-closed");
    } else {
      sessionStorage.setItem("sidebar-closed", "true");
    }
  }, [sidebarClosed]);

  useEffect(() => {
    fetch(`${API_URL}users/getUser.php?token=${token}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setToken();
          navigate("/login");
        } else {
          setUserPermissions(parseInt(response.permissions));
          setUserName(response.name);
          setUserUsername(response.username);
        }
      });
  }, [token]);

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/current-year.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          setCurrentYear(parseInt(response));
        }
      });
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "filteredDatesOrders",
      JSON.stringify(filteredDatesOrders)
    );
  }, [filteredDatesOrders.length]);

  return (
    <Context.Provider
      value={{
        token,
        setToken,
        section,
        setSection,
        sidebarClosed,
        toggleSidebar,
        setSidebarClosed,
        userPermissions,
        setUserPermissions,
        userName,
        userUsername,
        currentYear,
        selectedFiltersOrders,
        setSelectedFiltersOrders,
        ordersIsFiltered,
        setOrdersIsFiltered,
        selectedFiltersInventorySummary,
        setSelectedFiltersInventorySummary,
        inventorySummaryIsFiltered,
        setInventorySummaryIsFiltered,
        selectedFiltersInventorySummaryFloripa,
        setSelectedFiltersInventorySummaryFloripa,
        inventorySummaryIsFilteredFloripa,
        setInventorySummaryIsFilteredFloripa,
        selectedFiltersInventorySummaryStore,
        setSelectedFiltersInventorySummaryStore,
        inventorySummaryIsFilteredStore,
        setInventorySummaryIsFilteredStore,
        selectedFiltersInventoryDetails,
        setSelectedFiltersInventoryDetails,
        inventoryDetailsIsFiltered,
        setInventoryDetailsIsFiltered,
        selectedFiltersInventoryDetailsFloripa,
        setSelectedFiltersInventoryDetailsFloripa,
        inventoryDetailsIsFilteredFloripa,
        setInventoryDetailsIsFilteredFloripa,
        selectedFiltersInventoryDetailsStore,
        setSelectedFiltersInventoryDetailsStore,
        inventoryDetailsIsFilteredStore,
        setInventoryDetailsIsFilteredStore,
        filteredDatesOrders,
        setFilteredDatesOrders,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StoreProvider;
