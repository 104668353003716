import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import StoreProvider from "../components/Store/Provider";
import RoutesPrivate from "../components/Routes/Private/Private";

import Login from "./Login";
import OrderInput from "./OrderInput";
import OrderList from "./OrderList";
import OrderUpdate from "./OrderUpdate";
import InternalPurchase from "./InternalPurchase";
import InventorySummary from "./InventorySummary";
import InventoryDetails from "./InventoryDetails";
import InternalPurchaseUpdate from "./InternalPurchaseUpdate";
import Summary from "./Summary";
import Settings from "./Settings";
import DeliveryInput from "./DeliveryInput";
import DeliveryList from "./DeliveryList";
import DeliveryUpdate from "./DeliveryUpdate";
import TransferInput from "./TransferInput";
import TransferList from "./TransferList";
import TransferUpdate from "./TransferUpdate";
import OrderListCanceled from "./OrderListCanceled";
import OrderListNonPaid from "./OrderListNonPaid";
import ComissionList from "./ComissionList";
import ComissionUpdate from "./ComissionUpdate";
import Users from "./Users";
import MyUser from "./MyUser";
import Charts from "./Charts";
import OrderInputFloripa from "./OrderInputFloripa";
import SettingsFloripa from "./SettingsFloripa";
import OrderListFloripa from "./OrderListFloripa";
import InternalPurchaseFloripa from "./InternalPurchaseFloripa";
import InventorySummaryFloripa from "./InventorySummaryFloripa";
import InternalPurchaseUpdateFloripa from "./InternalPurchaseUpdateFloripa";
import SummaryFloripa from "./SummaryFloripa";
import OrderListCanceledFloripa from "./OrderListCanceledFloripa";
import OrderUpdateFloripa from "./OrderUpdateFloripa";
import SelectSection from "./SelectSection";
import OrderInputStore from "./OrderInputStore";
import SettingsStore from "./SettingsStore";
import OrderListStore from "./OrderListStore";
import InternalPurchaseStore from "./InternalPurchaseStore";
import InventorySummaryStore from "./InventorySummaryStore";
import SummaryStore from "./SummaryStore";
import OrderListCanceledStore from "./OrderListCanceledStore";
import OrderUpdateStore from "./OrderUpdateStore";
import InternalPurchaseTransfer from "./InternalPurchaseTransfer";
import InventorySummaryTransfer from "./InventorySummaryTransfer";
import InternalPurchaseUpdateTransfer from "./InternalPurchaseUpdateTransfer";
import SummaryTransfer from "./SummaryTransfer";
import InternalPurchaseUpdateStore from "./InternalPurchaseUpdateStore";

const PagesRoot = () => (
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StoreProvider>
        <Routes>
          <Route
            path="/"
            element={<RoutesPrivate component={SelectSection} />}
          />
          <Route
            path="/order-input"
            element={
              <RoutesPrivate component={OrderInput} permissions={[1, 5, 4]} />
            }
          />
          <Route
            path="/order-list"
            element={
              <RoutesPrivate
                component={OrderList}
                permissions={[1, 5, 2, 3, 4]}
              />
            }
          />
          <Route
            path="/order-update"
            element={
              <RoutesPrivate component={OrderUpdate} permissions={[1, 5, 4]} />
            }
          />
          <Route
            path="/internal-purchase"
            element={
              <RoutesPrivate
                component={InternalPurchase}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/internal-purchase-update"
            element={
              <RoutesPrivate
                component={InternalPurchaseUpdate}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/inventory"
            element={
              <RoutesPrivate
                component={InventorySummary}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/inventory-details"
            element={
              <RoutesPrivate
                component={InventoryDetails}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/summary"
            element={
              <RoutesPrivate component={Summary} permissions={[1, 5, 2, 4]} />
            }
          />
          <Route
            path="/order-list-canceled"
            element={
              <RoutesPrivate
                component={OrderListCanceled}
                permissions={[1, 5, 2, 3]}
              />
            }
          />
          <Route
            path="/order-list-nonpaid"
            element={
              <RoutesPrivate
                component={OrderListNonPaid}
                permissions={[1, 5, 2, 3]}
              />
            }
          />
          <Route
            path="/comission-list"
            element={
              <RoutesPrivate
                component={ComissionList}
                permissions={[1, 5, 2, 3]}
              />
            }
          />
          <Route
            path="/comission-update"
            element={
              <RoutesPrivate component={ComissionUpdate} permissions={[1, 5]} />
            }
          />
          <Route
            path="/delivery-input"
            element={
              <RoutesPrivate
                component={DeliveryInput}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/delivery-list"
            element={
              <RoutesPrivate
                component={DeliveryList}
                permissions={[1, 5, 2, 3, 4]}
              />
            }
          />
          <Route
            path="/delivery-update"
            element={
              <RoutesPrivate
                component={DeliveryUpdate}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/transfer-input"
            element={
              <RoutesPrivate
                component={TransferInput}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/transfer-list"
            element={
              <RoutesPrivate
                component={TransferList}
                permissions={[1, 5, 2, 3, 4]}
              />
            }
          />
          <Route
            path="/transfer-update"
            element={
              <RoutesPrivate
                component={TransferUpdate}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/internal-purchase-transfer"
            element={
              <RoutesPrivate
                component={InternalPurchaseTransfer}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/internal-purchase-update-transfer"
            element={
              <RoutesPrivate
                component={InternalPurchaseUpdateTransfer}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/inventory-transfer"
            element={
              <RoutesPrivate
                component={InventorySummaryTransfer}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/summary-transfer"
            element={
              <RoutesPrivate
                component={SummaryTransfer}
                permissions={[1, 5, 2, 4]}
              />
            }
          />
          <Route
            path="/charts"
            element={<RoutesPrivate component={Charts} permissions={[1, 5]} />}
          />
          <Route
            path="/settings"
            element={
              <RoutesPrivate component={Settings} permissions={[1, 5]} />
            }
          />
          <Route
            path="/users"
            element={<RoutesPrivate component={Users} permissions={[1, 5]} />}
          />
          <Route
            path="/my-user"
            element={
              <RoutesPrivate component={MyUser} permissions={[1, 5, 2, 3, 4]} />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/order-input-floripa"
            element={
              <RoutesPrivate
                component={OrderInputFloripa}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/order-list-floripa"
            element={
              <RoutesPrivate
                component={OrderListFloripa}
                permissions={[1, 5, 2, 3, 4]}
              />
            }
          />
          <Route
            path="/settings-floripa"
            element={
              <RoutesPrivate
                component={SettingsFloripa}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/internal-purchase-floripa"
            element={
              <RoutesPrivate
                component={InternalPurchaseFloripa}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/internal-purchase-update-floripa"
            element={
              <RoutesPrivate
                component={InternalPurchaseUpdateFloripa}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/inventory-floripa"
            element={
              <RoutesPrivate
                component={InventorySummaryFloripa}
                permissions={[1, 5, 2]}
              />
            }
          />
          <Route
            path="/summary-floripa"
            element={
              <RoutesPrivate
                component={SummaryFloripa}
                permissions={[1, 5, 2, 4]}
              />
            }
          />
          <Route
            path="/order-list-canceled-floripa"
            element={
              <RoutesPrivate
                component={OrderListCanceledFloripa}
                permissions={[1, 5, 2, 3]}
              />
            }
          />
          <Route
            path="/order-update-floripa"
            element={
              <RoutesPrivate
                component={OrderUpdateFloripa}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/order-input-store"
            element={
              <RoutesPrivate
                component={OrderInputStore}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/settings-store"
            element={
              <RoutesPrivate
                component={SettingsStore}
                permissions={[1, 5, 4]}
              />
            }
          />
          <Route
            path="/order-list-store"
            element={
              <RoutesPrivate
                component={OrderListStore}
                permissions={[1, 5, 2, 3, 4]}
              />
            }
          />
          <Route
            path="/internal-purchase-store"
            element={
              <RoutesPrivate
                component={InternalPurchaseStore}
                permissions={[1, 5, 2, 4]}
              />
            }
          />
          <Route
            path="/internal-purchase-update-store"
            element={
              <RoutesPrivate
                component={InternalPurchaseUpdateStore}
                permissions={[1, 5, 2, 4]}
              />
            }
          />
          <Route
            path="/inventory-store"
            element={
              <RoutesPrivate
                component={InventorySummaryStore}
                permissions={[1, 5, 2, 4]}
              />
            }
          />
          <Route
            path="/summary-store"
            element={
              <RoutesPrivate
                component={SummaryStore}
                permissions={[1, 5, 2, 4]}
              />
            }
          />
          <Route
            path="/order-list-canceled-store"
            element={
              <RoutesPrivate
                component={OrderListCanceledStore}
                permissions={[1, 5, 2, 3, 4]}
              />
            }
          />
          <Route
            path="/order-update-store"
            element={
              <RoutesPrivate
                component={OrderUpdateStore}
                permissions={[1, 5, 4]}
              />
            }
          />
        </Routes>
      </StoreProvider>
    </LocalizationProvider>
  </BrowserRouter>
);

export default PagesRoot;
