import React, { useState, useCallback, useContext } from "react";
import ConfirmationNumberRoundedIcon from "@mui/icons-material/ConfirmationNumberRounded";
import DeliveryDiningRoundedIcon from "@mui/icons-material/DeliveryDiningRounded";
import AirportShuttleRoundedIcon from "@mui/icons-material/AirportShuttleRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import InsightsIcon from "@mui/icons-material/Insights";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import { useNavigate } from "react-router-dom";

import StoreContext from "../Store/Context";

import LogoImg from "../../assets/logo-riotickets.png";
import {
  Main,
  Logo,
  NavigationItem,
  NavigationItemUl,
  Navigation,
  NavigationSubItem,
  NavigationItemTitle,
  CloseSidebarButton,
  ExitButton,
  CurrentYear,
  GoBackButton,
  OpenSidebarButton,
} from "./sidebar";
import { Tooltip } from "@mui/material";

const Sidebar = () => {
  const [navItemsOpened, setNavItemsOpened] = useState([]);

  const {
    sidebarClosed,
    toggleSidebar,
    setToken,
    userPermissions,
    currentYear,
    section,
  } = useContext(StoreContext);

  const navigate = useNavigate();

  const toggleNavItem = useCallback(
    (name) => {
      if (navItemsOpened.includes(name)) {
        let aux = navItemsOpened;
        aux = aux.filter((item) => item !== name);
        setNavItemsOpened(aux);
      } else {
        setNavItemsOpened([...navItemsOpened, name]);
      }
    },
    [navItemsOpened]
  );

  const logout = useCallback(() => {
    setToken();
  }, []);

  return (
    <Main sidebarclosed={sidebarClosed.toString()}>
      <ExitButton onClick={logout}>Logout</ExitButton>
      <Logo src={LogoImg} sidebarclosed={sidebarClosed.toString()} />
      {section && (
        <>
          <CurrentYear sidebarclosed={sidebarClosed.toString()}>
            Current Year: {currentYear}
          </CurrentYear>
          <CurrentYear sidebarclosed={sidebarClosed.toString()}>
            Section: {section && section.toUpperCase()}
          </CurrentYear>
          {sidebarClosed ? (
            <Tooltip title="Open Sidebar" placement="right">
              <OpenSidebarButton onClick={() => toggleSidebar()} />
            </Tooltip>
          ) : (
            <Tooltip title="Close Sidebar" placement="right">
              <CloseSidebarButton onClick={() => toggleSidebar()} />
            </Tooltip>
          )}
          <Tooltip title="Back Page" placement="right">
            <GoBackButton onClick={() => navigate(-1)} />
          </Tooltip>

          {section === "rio" && (
            <Navigation sidebarclosed={sidebarClosed.toString()}>
              <NavigationItem>
                <NavigationItemTitle
                  subItems={true}
                  onClick={() => toggleNavItem("tickets")}
                  opened={navItemsOpened.includes("tickets")}
                >
                  <ConfirmationNumberRoundedIcon />
                  TICKETS
                </NavigationItemTitle>
                <NavigationItemUl opened={navItemsOpened.includes("tickets")}>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-input">Input</a>
                  </NavigationSubItem>
                  <NavigationSubItem>
                    <a href="/order-list">List</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/internal-purchase">Internal Purchase</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/inventory">Inventory</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/summary">Summary</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-list-canceled">Canceled</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-list-nonpaid">Non Paid</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/comission-list">Comission List</a>
                  </NavigationSubItem>
                </NavigationItemUl>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle
                  subItems={true}
                  onClick={() => toggleNavItem("delivery")}
                  opened={navItemsOpened.includes("delivery")}
                >
                  <DeliveryDiningRoundedIcon />
                  DELIVERY
                </NavigationItemTitle>
                <NavigationItemUl opened={navItemsOpened.includes("delivery")}>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/delivery-input">Input</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/delivery-list">List</a>
                  </NavigationSubItem>
                </NavigationItemUl>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle
                  subItems={true}
                  onClick={() => toggleNavItem("transfer")}
                  opened={navItemsOpened.includes("transfer")}
                >
                  <AirportShuttleRoundedIcon />
                  TRANSFER
                </NavigationItemTitle>
                <NavigationItemUl opened={navItemsOpened.includes("transfer")}>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/transfer-input">Input</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/transfer-list">List</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/internal-purchase-transfer">Internal Purchase</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/inventory-transfer">Inventory</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/summary-transfer">Summary</a>
                  </NavigationSubItem>
                </NavigationItemUl>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/settings">
                    <SettingsSuggestRoundedIcon /> SETTINGS
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/users">
                    <PersonRoundedIcon />
                    USERS{" "}
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/charts">
                    <InsightsIcon />
                    CHARTS{" "}
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle>
                  <a href="/my-user">
                    <PersonRoundedIcon />
                    MY USER
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle>
                  <a href="/">
                    <ChangeCircleIcon />
                    CHANGE SECTION
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
            </Navigation>
          )}

          {section === "floripa" && (
            <Navigation sidebarclosed={sidebarClosed.toString()}>
              <NavigationItem>
                <NavigationItemTitle
                  subItems={true}
                  onClick={() => toggleNavItem("tickets")}
                  opened={navItemsOpened.includes("tickets")}
                >
                  <ConfirmationNumberRoundedIcon />
                  TICKETS
                </NavigationItemTitle>
                <NavigationItemUl opened={navItemsOpened.includes("tickets")}>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-input-floripa">Input</a>
                  </NavigationSubItem>
                  <NavigationSubItem>
                    <a href="/order-list-floripa">List</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/internal-purchase-floripa">Internal Purchase</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/inventory-floripa">Inventory</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/summary-floripa">Summary</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-list-canceled-floripa">Canceled</a>
                  </NavigationSubItem>
                </NavigationItemUl>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/settings-floripa">
                    <SettingsSuggestRoundedIcon /> SETTINGS
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/users">
                    <PersonRoundedIcon />
                    USERS{" "}
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/charts">
                    <InsightsIcon />
                    CHARTS{" "}
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle>
                  <a href="/my-user">
                    <PersonRoundedIcon />
                    MY USER
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle>
                  <a href="/">
                    <ChangeCircleIcon />
                    CHANGE SECTION
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
            </Navigation>
          )}

          {section === "store" && (
            <Navigation sidebarclosed={sidebarClosed.toString()}>
              <NavigationItem>
                <NavigationItemTitle
                  subItems={true}
                  onClick={() => toggleNavItem("tickets")}
                  opened={navItemsOpened.includes("tickets")}
                >
                  <ConfirmationNumberRoundedIcon />
                  ORDERS
                </NavigationItemTitle>
                <NavigationItemUl opened={navItemsOpened.includes("tickets")}>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-input-store">Input</a>
                  </NavigationSubItem>
                  <NavigationSubItem>
                    <a href="/order-list-store">List</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/internal-purchase-store">Internal Purchase</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/inventory-store">Inventory</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/summary-store">Summary</a>
                  </NavigationSubItem>
                  <NavigationSubItem
                    hide={(
                      [1, 5, 2, 3, 4].indexOf(userPermissions) === -1
                    ).toString()}
                  >
                    <a href="/order-list-canceled-store">Canceled</a>
                  </NavigationSubItem>
                </NavigationItemUl>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5, 4].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/settings-store">
                    <SettingsSuggestRoundedIcon /> SETTINGS
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/users">
                    <PersonRoundedIcon />
                    USERS{" "}
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem
                hide={([1, 5].indexOf(userPermissions) === -1).toString()}
              >
                <NavigationItemTitle>
                  <a href="/charts">
                    <InsightsIcon />
                    CHARTS{" "}
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle>
                  <a href="/my-user">
                    <PersonRoundedIcon />
                    MY USER
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
              <NavigationItem>
                <NavigationItemTitle>
                  <a href="/">
                    <ChangeCircleIcon />
                    CHANGE SECTION
                  </a>
                </NavigationItemTitle>
              </NavigationItem>
            </Navigation>
          )}
        </>
      )}
    </Main>
  );
};

export default Sidebar;
