import React, { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import Sidebar from "../../components/Sidebar";
import {
  DeleteButton,
  Main,
  SubTitle,
  Table,
  TableActions,
  Tables,
  Title,
  FormBox,
  FormRow,
  SettingGroup,
  ContentInner,
} from "./selectsection";

import { Content } from "../../utils/stylesbase";

const SelectSection = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const { sidebarClosed, setSection } = useContext(StoreContext);

  const select = useCallback((section) => {
    setSection(section);
    if (section === "rio") {
      navigate("order-input");
    } else if (section === "floripa") {
      navigate("order-input-floripa");
    } else if (section === "store") {
      navigate("order-input-store");
    }
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <Title>Select Section</Title>
        <ContentInner>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => select("rio")}
          >
            Rio
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => select("floripa")}
          >
            Floripa
          </Button>
          <Button
            variant="outlined"
            type="submit"
            onClick={() => select("store")}
          >
            Store
          </Button>
        </ContentInner>
      </Content>
    </Main>
  );
};

export default SelectSection;
