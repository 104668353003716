import React, { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { TextField, Button } from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import Sidebar from "../../components/Sidebar";
import {
  Main,
  Title,
  FormBox,
  FormRow,
  SettingGroup,
  ContentInner,
} from "./myuser";

import { Content } from "../../utils/stylesbase";
import { API_URL } from "../../utils/env";

const MyUser = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [formsStatus, setFormsStatus] = useState(0);
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    password: "",
  });

  const { sidebarClosed, userName, userUsername, token } =
    useContext(StoreContext);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    fetch(`${API_URL}users/update.php`, {
      method: "POST",
      body: JSON.stringify({
        ...formData,
        token,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!response.error) {
          MySwal.fire({
            title: <p>Sucess</p>,
            html: <i>User update successfully</i>,
            icon: "success",
          }).then(() => {
            return navigate(0);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.error,
          });
        }
      });
  }, []);

  const onChange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  useEffect(() => {
    let newFormData = formData;
    formData.name = userName;
    formData.username = userUsername;
    setFormData(newFormData);
    setFormsStatus(Math.random());
  }, [userName, userUsername]);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <Title>My User</Title>
        <ContentInner>
          <SettingGroup>
            <FormBox
              component="form"
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
              name="edit-my-user"
            >
              <FormRow>
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  name="username"
                  onChange={onChange}
                  value={formData.username}
                  autoComplete="none"
                  required
                />
              </FormRow>
              <FormRow>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  name="name"
                  onChange={onChange}
                  value={formData.name}
                  required
                />
              </FormRow>
              <FormRow>
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  onChange={onChange}
                  value={formData.password}
                  type="password"
                  autoComplete="new-password"
                  helperText="Leave blank if you don't want to change the password"
                />
              </FormRow>
              <FormRow>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>
        </ContentInner>
      </Content>
    </Main>
  );
};

export default MyUser;
