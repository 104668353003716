import React, { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import Sidebar from "../../components/Sidebar";
import {
  DeleteButton,
  Main,
  Table,
  TableActions,
  Tables,
  Title,
  FormBox,
  FormRow,
  SettingGroup,
  ContentInner,
  NewTitle,
} from "./users";

import { Content } from "../../utils/stylesbase";
import { API_URL } from "../../utils/env";

const Users = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const permissions = [
    "Superadmin",
    "Financier",
    "Analyst",
    "Operator",
    "Read-Only",
  ];

  const [users, setUsers] = useState([]);
  const [formsStatus, setFormsStatus] = useState(0);
  const [formData, setFormData] = useState({
    username: "",
    name: "",
    permissions: "",
    authorizing: "",
    password: "",
  });

  const { sidebarClosed, userPermissions } = useContext(StoreContext);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if ([5].indexOf(userPermissions) !== -1) return;

      fetch(`${API_URL}users/create.php`, {
        method: "POST",
        body: JSON.stringify({
          ...formData,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!response.error) {
            MySwal.fire({
              title: <p>Sucess</p>,
              html: <i>User created successfully</i>,
              icon: "success",
            }).then(() => {
              return navigate(0);
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.error,
            });
          }
        });
    },
    [userPermissions]
  );

  const onChange = useCallback(
    (e) => {
      let newFormData = formData;
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
      setFormsStatus(Math.random());
    },
    [formData]
  );

  const deleteUser = useCallback(
    (userId) => {
      if ([5].indexOf(userPermissions) !== -1) return;
      MySwal.fire({
        title: "Are you sure you want to delete the user?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`${API_URL}users/delete.php?id=${userId}`, {
            method: "POST",
          })
            .then((response) => response.json())
            .then((response) =>
              Swal.fire("User removed successfully!!", "", "success").then(
                () => {
                  return navigate(0);
                }
              )
            );
        }
      });
    },
    [userPermissions]
  );

  useEffect(() => {
    fetch(`${API_URL}users/list-all.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let formatedResponse = response;
        formatedResponse.forEach((user) => {
          user.permissions = permissions[parseInt(user.permissions) - 1];
        });
        setUsers(formatedResponse);
      });
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <Title>Users</Title>
        <ContentInner>
          <SettingGroup>
            <Tables>
              <Table>
                <thead>
                  <tr>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Permission</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user) => {
                    return (
                      <tr key={user.id}>
                        <td>{user.username}</td>
                        <td>{user.name}</td>
                        <td>{user.permissions}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <TableActions>
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  {users.map((user) => {
                    return (
                      <tr key={user.id}>
                        <td>
                          <DeleteButton onClick={() => deleteUser(user.id)} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </TableActions>
            </Tables>
            <NewTitle>New User</NewTitle>
            <FormBox
              component="form"
              autoComplete="off"
              onSubmit={onSubmit}
              status={formsStatus}
              name="new-user"
            >
              <FormRow>
                <TextField
                  id="username"
                  label="Username"
                  variant="outlined"
                  name="username"
                  onChange={onChange}
                  value={formData.username}
                  autoComplete="none"
                  required
                />
              </FormRow>
              <FormRow>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  name="name"
                  onChange={onChange}
                  value={formData.name}
                  required
                />
              </FormRow>
              <FormRow>
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  onChange={onChange}
                  value={formData.password}
                  type="password"
                  autoComplete="new-password"
                  required
                />
              </FormRow>
              <FormRow>
                <FormControl fullWidth>
                  <InputLabel id="permissions-label">Permissions</InputLabel>
                  <Select
                    labelId="permissions-label"
                    id="permissions"
                    label="Permissions"
                    name="permissions"
                    onChange={onChange}
                    value={formData.permissions}
                    required
                  >
                    {permissions.map((permission, index) => {
                      return (
                        <MenuItem value={index + 1} key={permission}>
                          {permission}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </FormRow>
              <FormRow>
                <Button variant="outlined" type="submit">
                  Save
                </Button>
              </FormRow>
            </FormBox>
          </SettingGroup>
        </ContentInner>
      </Content>
    </Main>
  );
};

export default Users;
