import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const Content = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: ${(props) =>
    props.sidebarclosed === "true"
      ? "calc(100% - 180px)"
      : "calc(100% - 450px)"};
  justify-content: center;
`;

const Filter = styled.div`
  align-self: center;
  min-width: 250px;
  position: fixed;
  display: ${(props) => (props.opened === "true" ? "flex" : "none")};
  flex-direction: column;
  background: #fff;
  border: solid 1px #00000050;
  border-radius: 5px;
  z-index: 10;
  padding: 10px;
  padding-right: 10px;
  top: 200px;

  form {
    width: 100%;
  }

  .MuiFormGroup-root {
    align-items: center;
  }

  ul {
    padding-left: 0;
    list-style-type: none;

    li {
      padding-left: 0;
      display: flex;
      justify-content: flex-start;
    }
  }

  input[type="date"][value=""],
  input[type="time"][value=""] {
    color: transparent;
  }

  .Mui-focused input[type="date"][value=""],
  .Mui-focused input[type="time"][value=""] {
    color: initial;
  }

  .date-filter {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  .filters-actions {
    display: flex;
    gap: 20px;

    span {
      cursor: pointer;
    }
  }

  .filters-search {
    display: flex;
    margin-top: 15px;

    .MuiInputBase-root {
      width: 100%;
    }

    .MuiFormControl-root {
      width: 100%;
    }
  }

  .filters-options {
    max-height: 50vh;
    overflow-y: scroll;
  }
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 500;
`;

const CloseFilter = styled(CloseIcon)`
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const Indicators = styled.table`
  min-width: 300px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  border: solid 1px #ccc;
  padding: 0;
  border-spacing: 0;

  thead {
    background: #e6e6e6;

    th {
      border-bottom: solid 1px #ccc;
      &:not(:first-child) {
        border-left: solid 1px #ccc;
      }
    }
  }

  tbody {
    tr {
      td:not(:first-child) {
        border-left: solid 1px #ccc;
      }
    }
  }
`;

const FulfillModal = styled.div`
  width: 300px;
  position: fixed;
  display: ${(props) => (props.opened === "true" ? "flex" : "none")};
  color: #545454;
  flex-direction: column;
  background: #fff;
  border: solid 1px #00000050;
  border-radius: 5px;
  z-index: 10;
  padding: 20px;
  top: 200px;
  left: calc(50% - 150px);
  max-height: 500px;
  overflow: auto;

  .MuiFormControlLabel-label {
    text-transform: capitalize;
  }

  button {
    width: max-content;
    margin: 0 auto;
    margin-top: 10px;
    border: 0;
    border-radius: 0.25em;
    background-color: #7066e0;
    color: #fff;
    font-size: 1em;

    &:hover {
      background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
      background-color: #7066e0;
      border: none !important;
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba(112, 102, 224, 0.5);
    }
  }

  .m-bottom {
    margin-bottom: 30px;
  }
`;

const FulfillModalTitle = styled.span`
  margin-bottom: 15px;
  padding-right: 50px;
  font-size: 1.4em;
  font-weight: 600;
  color: rgb(84, 84, 84);
  text-align: center;
`;

const CloseFulfillModal = styled(CloseIcon)`
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  display: flex;
`;

const Overlay = styled.div`
  display: ${(props) => (props.opened === "true" ? "flex" : "none")};
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;

const DownloadExcelWrapper = styled.div`
  width: max-content;
`;

export {
  Content,
  Filter,
  FilterTitle,
  CloseFilter,
  Indicators,
  FulfillModal,
  FulfillModalTitle,
  CloseFulfillModal,
  FormBox,
  DownloadExcelWrapper,
  Overlay,
};
