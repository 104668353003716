import React, { useState, useContext, useCallback } from "react";

import Sidebar from "../../components/Sidebar";
import { Main, Title, SelectChart } from "./charts";

import { Content } from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import TicketsPerYear from "../../components/Charts/TicketsPerYear";
import { Button } from "@mui/material";
import TicketsPerCountry from "../../components/Charts/TicketsPerCountry";

const Charts = () => {
  const { sidebarClosed } = useContext(StoreContext);

  const [reset, setReset] = useState(0);
  const [selectedChart, setSelectedChart] = useState(null);

  const handleSelectChart = useCallback((chart) => {
    setSelectedChart(chart);
    setReset(Math.random());
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()} key={reset}>
        <Title>Charts</Title>
        <SelectChart>
          {(selectedChart === "ticket-per-country" ||
            selectedChart === null) && (
            <Button
              variant="outlined"
              onClick={() => handleSelectChart("ticket-per-year")}
            >
              Sales per Month
            </Button>
          )}
          {(selectedChart === "ticket-per-year" || selectedChart === null) && (
            <Button
              variant="outlined"
              onClick={() => handleSelectChart("ticket-per-country")}
            >
              Sales per Country
            </Button>
          )}
        </SelectChart>
        {selectedChart === "ticket-per-year" && (
          <TicketsPerYear setReset={setReset} />
        )}
        {selectedChart === "ticket-per-country" && (
          <TicketsPerCountry setReset={setReset} />
        )}
      </Content>
    </Main>
  );
};

export default Charts;
