import styled from "styled-components";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";

const Main = styled.div`
  background: #fcfcfc;
  position: relative;
  width: 100%;
  display: flex;
  min-height: 100vh;
`;

const Content = styled.div`
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 450px);
`;

const SubTitle = styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: #728095;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
`;

const Table = styled.table`
  overflow-x: scroll;
  white-space: nowrap;
  display: table-caption;
  text-align: center;
  border-spacing: 0;
  border-radius: 5px;
  border: solid 1px #000;
  min-width: 100%;

  thead {
    background: #e6e6e6;
    height: 50px;
  }

  thead th {
    border-bottom: solid 1px #000;
  }

  tr {
    height: 50px;
  }

  tr:not(:last-child) td {
    border-bottom: solid 1px #ccc;
  }

  th {
    padding: 10px 20px;
    background: #fff;
  }

  th:not(:last-child):not(.titles) {
    border-right: solid 1px #ccc;
  }

  td {
    padding: 8px;
  }

  td:not(.sector) {
    font-size: 16px;
  }

  td:not(:last-child) {
    border-right: solid 1px #ccc;
  }

  td:nth-child(4n + 1):not(:last-child) {
    border-right: solid 1px #000;
  }

  th.titles:nth-child(4n + 1):not(:last-child) {
    border-right: solid 1px #000;
  }

  th {
    border-right: solid 1px #000 !important;
  }
  /* 
  th.titles {
    border-right: solid 1px #fff !important;
  } */

  th {
    background: #737373;
    color: #fff;
  }

  tr:nth-child(2n) {
    background: #e6e6e6;
  }

  th:last-child span:last-child {
    border-right: none;
  }
  td:last-child span:last-child {
    border-right: none;
  }
  .sector {
    border-right: solid 1px #ccc;
  }

  th,
  td {
    width: 1% !important;
  }
`;

const Tables = styled.div`
  display: flex;
  gap: 20px;
`;

const DeleteButton = styled(DeleteTwoToneIcon)`
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
`;

const EditButton = styled(TuneTwoToneIcon)`
  background: #ccc;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
`;

const SubTr = styled.tr`
  th {
  }
  span {
    font-size: 15px;
    text-transform: uppercase;
    width: calc(25% - 1px - 10px);
    height: calc(100% - 10px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  td.owing {
    color: red !important;
  }

  span {
    border-right: solid 1px #ccc;
  }

  td {
    height: 1px;
  }

  th.titles {
    padding: 0;
    height: 1px;
    width: 25%;
    font-size: 8px;
    padding: 8px;
  }

  .sector {
    padding: 0 10px;
  }
`;

const Filters = styled.div`
  display: flex;
  gap: 20px;

  > * {
    width: 200px;
    max-width: 200px;
  }

  input[type="date"][value=""],
  input[type="time"][value=""] {
    color: transparent;
  }

  .Mui-focused input[type="date"][value=""],
  .Mui-focused input[type="time"][value=""] {
    color: initial;
  }
`;

export {
  Main,
  SubTitle,
  Content,
  Title,
  Table,
  Tables,
  DeleteButton,
  EditButton,
  SubTr,
  Filters,
};
