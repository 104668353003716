import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import Sidebar from "../../components/Sidebar";
import {
  DeleteButton,
  EditButton,
  Main,
  SubTitle,
  Table,
  TableActions,
  Tables,
  Title,
  ComissionRow,
} from "./comissionlist";

import { Content, DownloadExcelWrapper } from "../../utils/stylesbase";
import { Button, Tooltip } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { API_URL } from "../../utils/env";

const ComissionsList = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [comissions, setComissions] = useState([]);

  const { sidebarClosed, userPermissions, setSidebarClosed } =
    useContext(StoreContext);

  const tableRef = useRef(null);

  const deleteComission = useCallback((comissionId, orderRef) => {
    MySwal.fire({
      title: `Are you sure you want to delete the comission on booking ${orderRef}?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${API_URL}comissions/delete.php?id=${comissionId}`, {
          method: "POST",
        })
          .then((response) => response.json())
          .then((response) =>
            Swal.fire("Comission removed successfully!!", "", "success").then(
              () => {
                return navigate(0);
              }
            )
          );
      }
    });
  }, []);

  const editComission = useCallback((comissionId) => {
    navigate(`/comission-update?id=${comissionId}`);
  }, []);

  useEffect(() => {
    setSidebarClosed(true);
    fetch(`${API_URL}comissions/list-all.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => setComissions(response));
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Comissions</SubTitle>
        <Title>List</Title>
        <DownloadExcelWrapper>
          <DownloadTableExcel
            filename="Comission-List"
            sheet="comission-list"
            currentTableRef={tableRef.current}
          >
            <Button variant="outlined" style={{ marginBottom: "20px" }}>
              Export to excel
            </Button>
          </DownloadTableExcel>
        </DownloadExcelWrapper>
        <Tables>
          <Table ref={tableRef}>
            <thead>
              <tr>
                <th>#</th>
                <th>Comissioner's Name</th>
                <th>Comissioner's Contact</th>
                <th>Comission Currency</th>
                <th>Comission Price</th>
                <th>Comission Paid?</th>
                <th>Created By</th>
                <th>Last Edit By</th>{" "}
                {[1].indexOf(userPermissions) !== -1 && (
                  <th className="sticky-right"></th>
                )}
              </tr>
            </thead>
            <tbody>
              {comissions.map((comission) => {
                return (
                  <ComissionRow
                    key={comission.id}
                    paid={(comission.comissionPaid === "1").toString()}
                  >
                    <td>{comission.orderRef}</td>
                    <td>{comission.comissionersName}</td>
                    <td>{comission.comissionersContact}</td>
                    <td>{comission.comissionCurrency}</td>
                    <td>{comission.comissionPrice}</td>
                    <td>
                      {comission.comissionPaid === "1" ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </td>
                    <td>{comission.createdBy}</td>
                    <td>{comission.lastEditBy}</td>
                    {[1].indexOf(userPermissions) !== -1 && (
                      <>
                        <td className="sticky-right">
                          <Tooltip placement="top" title="Edit">
                            <EditButton
                              onClick={() => editComission(comission.id)}
                            />
                          </Tooltip>
                          <Tooltip placement="top" title="Delete">
                            <DeleteButton
                              onClick={() =>
                                deleteComission(
                                  comission.id,
                                  comission.orderRef
                                )
                              }
                            />
                          </Tooltip>
                        </td>
                      </>
                    )}
                  </ComissionRow>
                );
              })}
            </tbody>
          </Table>
        </Tables>
      </Content>
    </Main>
  );
};

export default ComissionsList;
