import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import Sidebar from "../../components/Sidebar";
import {
  EditButton,
  Main,
  SubTitle,
  Table,
  TableActions,
  Tables,
  Title,
  PayButton,
  DeleteButton,
} from "./orderlistnonpaid";

import { Content, DownloadExcelWrapper } from "../../utils/stylesbase";
import { Button, Tooltip } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { API_URL } from "../../utils/env";

const getMonth = (date) => {
  var month = date.getMonth() + 1;
  return month < 10 ? "0" + month : "" + month;
};

const getDay = (date) => {
  var day = date.getDate();
  return day < 10 ? "0" + day : "" + day;
};

const OrderListNonPaid = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [tickets, setTickets] = useState([]);

  const { sidebarClosed, userName, userPermissions, setSidebarClosed } =
    useContext(StoreContext);

  const tableRef = useRef(null);

  const curDate = new Date();
  const formatedCurDate = `${curDate.getFullYear()}-${getMonth(
    curDate
  )}-${getDay(curDate)}`;

  const deleteTicket = useCallback(
    async (ticketId, orderRef) => {
      MySwal.fire({
        title: `Are you sure you want to cancel the ticket on booking ${orderRef}?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
        input: "text",
        inputLabel: "Cancel Reason",
        inputPlaceholder: "Cancel Reason",
        inputAttributes: {
          name: "cancelReason",
        },
      }).then(({ ...params }) => {
        if (params.isConfirmed) {
          const body = {
            cancelReason: params.value,
            createdBy: userName,
            lastEditBy: userName,
          };
          fetch(`${API_URL}tickets/delete.php?id=${ticketId}`, {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((response) =>
              Swal.fire("Ticket cancelled successfully!!", "", "success").then(
                () => {
                  return navigate("/order-list-canceled");
                }
              )
            );
        }
      });
    },
    [userName]
  );

  const unNonPaidTicket = useCallback(
    async (ticketId, orderRef) => {
      MySwal.fire({
        title: `Are you sure you want to mark the ticket as paid on booking ${orderRef}?`,
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(({ ...params }) => {
        if (params.isConfirmed) {
          const body = {
            newRegistrationDate: formatedCurDate,
            createdBy: userName,
            lastEditBy: userName,
          };
          fetch(`${API_URL}tickets/unnonpaid.php?id=${ticketId}`, {
            method: "POST",
            body: JSON.stringify(body),
          })
            .then((response) => response.json())
            .then((response) =>
              Swal.fire(
                "Ticket marked as paid successfully!!",
                "",
                "success"
              ).then(() => {
                return navigate("/order-list");
              })
            );
        }
      });
    },
    [userName]
  );

  const editOrder = useCallback((orderId) => {
    navigate(`/order-update?id=${orderId}`);
  }, []);

  useEffect(() => {
    setSidebarClosed(true);
    fetch(`${API_URL}tickets/list-all-nonpaid.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => setTickets(response));
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Non Paid</SubTitle>
        <Title>List</Title>{" "}
        <DownloadExcelWrapper>
          <DownloadTableExcel
            filename="Non-paid-List"
            sheet="non-paid-list"
            currentTableRef={tableRef.current}
          >
            <Button variant="outlined" style={{ marginBottom: "20px" }}>
              Export to excel
            </Button>
          </DownloadTableExcel>
        </DownloadExcelWrapper>
        <Tables>
          <Table ref={tableRef}>
            <thead>
              <tr>
                <th>#</th>
                <th>Plataform</th>
                <th>Name</th>
                <th>Tickets Date</th>
                <th>Tickets</th>
                <th>Currency</th>
                <th>Price</th>
                <th>Pax</th>
                <th>Contact</th>
                <th>Discount</th>
                <th>Country</th>
                <th>Date of Registration</th>
                <th>Created By</th>
                <th>Last Edit By</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <tr key={ticket.ticket_id}>
                    <td>{ticket.orderRef}</td>
                    <td>{ticket.orderPlataform}</td>
                    <td>{ticket.name}</td>
                    <td>{ticket.date}</td>
                    <td>{ticket.sector}</td>
                    <td>{ticket.currency}</td>
                    <td>{ticket.price}</td>
                    <td>{ticket.pax}</td>
                    <td>{ticket.contact}</td>
                    <td>{ticket.discount}</td>
                    <td>{ticket.country}</td>
                    <td>{ticket.registeredDateFormated}</td>
                    <td>{ticket.createdBy}</td>
                    <td>{ticket.lastEditBy}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {[1].indexOf(userPermissions) !== -1 && (
            <TableActions>
              <thead>
                <tr></tr>
                <tr></tr>
              </thead>
              <tbody>
                {tickets.map((ticket) => {
                  return (
                    <tr key={ticket.ticket_id}>
                      <td>
                        <Tooltip placement="top" title="Edit">
                          <EditButton
                            onClick={() => editOrder(ticket.order_id)}
                          />
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip placement="top" title="Mark as paid">
                          <PayButton
                            onClick={() =>
                              unNonPaidTicket(ticket.ticket_id, ticket.orderRef)
                            }
                          />
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip placement="top" title="Delete">
                          <DeleteButton
                            onClick={() =>
                              deleteTicket(ticket.ticket_id, ticket.orderRef)
                            }
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </TableActions>
          )}
        </Tables>
      </Content>
    </Main>
  );
};

export default OrderListNonPaid;
