import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import StoreContext from "../../components/Store/Context";

import Sidebar from "../../components/Sidebar";
import {
  EditButton,
  Main,
  SubTitle,
  Table,
  Tables,
  Title,
  ReplayButton,
} from "./orderlistcanceled";

import { Content, DownloadExcelWrapper } from "../../utils/stylesbase";
import { Button, Tooltip } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { API_URL } from "../../utils/env";

const OrderListCanceled = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [tickets, setTickets] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [reset, setReset] = useState(0);

  const { sidebarClosed, userName, userPermissions, setSidebarClosed } =
    useContext(StoreContext);

  const tableRef = useRef(null);

  const unDeleteTicket = useCallback(async (ticketId, orderRef) => {
    MySwal.fire({
      title: `Are you sure you want to uncancel the ticket on booking ${orderRef}?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then(({ ...params }) => {
      if (params.isConfirmed) {
        const body = {
          createdBy: userName,
          lastEditBy: userName,
        };
        fetch(`${API_URL}tickets/undelete.php?id=${ticketId}`, {
          method: "POST",
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((response) =>
            Swal.fire("Ticket uncancelled successfully!!", "", "success").then(
              () => {
                return navigate("/order-list");
              }
            )
          );
      }
    });
  }, []);

  const editOrder = useCallback((orderId) => {
    navigate(`/order-update?id=${orderId}`);
  }, []);

  const toggleSelectedLine = useCallback(
    (ticketId) => {
      let newSelectedLines = selectedLines;
      if (newSelectedLines.indexOf(ticketId) === -1)
        newSelectedLines.push(ticketId);
      else newSelectedLines.splice(newSelectedLines.indexOf(ticketId), 1);
      setSelectedLines(newSelectedLines);
      setReset(Math.random());
    },
    [selectedLines]
  );

  useEffect(() => {
    setSidebarClosed(true);
    fetch(`${API_URL}tickets/list-all-canceled.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => setTickets(response));
  }, []);

  return (
    <Main status={reset}>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Canceled</SubTitle>
        <Title>List</Title>
        <DownloadExcelWrapper>
          <DownloadTableExcel
            filename="Canceled-List"
            sheet="canceled-list"
            currentTableRef={tableRef.current}
          >
            <Button variant="outlined" style={{ marginBottom: "20px" }}>
              Export to excel
            </Button>
          </DownloadTableExcel>
        </DownloadExcelWrapper>
        <Tables>
          <Table ref={tableRef}>
            <thead>
              <tr>
                <th>#</th>
                <th>Plataform</th>
                <th>Name</th>
                <th>Tickets Date</th>
                <th>Tickets</th>
                <th>Currency</th>
                <th>Price</th>
                <th>Pax</th>
                <th>Contact</th>
                <th>Discount</th>
                <th>Country</th>
                <th>Cancel Reason</th>
                <th>Created By</th>
                <th>Last Edit By</th>
                {[1].indexOf(userPermissions) !== -1 && (
                  <th className="sticky-right"></th>
                )}
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <tr
                    key={ticket.ticket_id}
                    onClick={() => toggleSelectedLine(ticket.ticket_id)}
                    className={
                      selectedLines.indexOf(ticket.ticket_id) !== -1
                        ? "selected"
                        : ""
                    }
                  >
                    <td>{ticket.orderRef}</td>
                    <td>{ticket.orderPlataform}</td>
                    <td>{ticket.name}</td>
                    <td>{ticket.date}</td>
                    <td>{ticket.sector}</td>
                    <td>{ticket.currency}</td>
                    <td>{ticket.price}</td>
                    <td>{ticket.pax}</td>
                    <td>{ticket.contact}</td>
                    <td>{ticket.discount}</td>
                    <td>{ticket.country}</td>
                    <td>{ticket.cancelReason}</td>
                    <td>{ticket.createdBy}</td>
                    <td>{ticket.lastEditBy}</td>
                    {[1].indexOf(userPermissions) !== -1 && (
                      <>
                        <td className="sticky-right">
                          <Tooltip placement="top" title="Edit">
                            <EditButton
                              onClick={() => editOrder(ticket.order_id)}
                            />
                          </Tooltip>
                          <Tooltip placement="top" title="Restore">
                            <ReplayButton
                              onClick={() =>
                                unDeleteTicket(
                                  ticket.ticket_id,
                                  ticket.orderRef
                                )
                              }
                            />
                          </Tooltip>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Tables>
      </Content>
    </Main>
  );
};

export default OrderListCanceled;
