import React, { useState, useEffect, useContext } from "react";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Autocomplete,
  Paper,
  Box,
  FormControlLabel,
  Divider,
} from "@mui/material";

import { Filters, Table } from "./styles";

import StoreContext from "../Store/Context";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { API_URL } from "../../utils/env";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TicketsPerCountry = (setReset) => {
  const { currentYear } = useContext(StoreContext);

  const [compareYear, setCompareYear] = useState(currentYear);
  const [filteredTicket, setFilteredTicket] = useState([]);
  const carnavalDays = [
    "access-1",
    "access-2",
    "special-1",
    "special-2",
    "special-3",
    "winners-parade",
    " ",
  ];
  const [filteredCarnavalDay, setFilteredCarnavalDay] = useState([
    "access-1",
    "access-2",
    "special-1",
    "special-2",
    "winners-parade",
    " ",
  ]);
  const [filteredCurrency, setFilteredCurrency] = useState([]);

  const [ticketsNames, setTicketsNames] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [dataTicketsByCountry, setDataTicketsByCountry] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    fetch(`${API_URL}dropdown-data/currencies.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        transformedData.push("");
        setCurrencies(transformedData);
        setFilteredCurrency(transformedData);
      });

    fetch(`${API_URL}dropdown-data/tickets.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.sort((a, b) => {
          const dateA = a.value.split('/').reverse().join('-');
          const dateB = b.value.split('/').reverse().join('-');
          return new Date(dateA) - new Date(dateB);
        }).forEach((data) => transformedData.push(data.value));
        transformedData.push("TRANSFER");
        transformedData.push("DELIVERY");
        transformedData.push("");
        setTicketsNames(transformedData);
        setFilteredTicket(transformedData);
      });
  }, []);

  useEffect(() => {
    fetch(
      `${API_URL}charts/tickets-per-country.php?compareYear=${compareYear}&ticket=${filteredTicket}&carnavalDay=${
        filteredCarnavalDay.length > 0 ? filteredCarnavalDay : "NULL"
      }&currency=${filteredCurrency.length > 0 ? filteredCurrency : "NULL"}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((item) => {
          if (item.name === "") item.name = "Empty";
          item.salesCurrentYear = parseInt(item.salesCurrentYear || 0);
          item.salesCompareYear = parseInt(item.salesCompareYear || 0);
          if (transformedData.find((item2) => item2.name === item.name)) {
            transformedData.find(
              (item2) => item2.name === item.name
            ).salesCurrentYear =
              (item.salesCurrentYear || 0) +
              (transformedData.find((item2) => item2.name === item.name)
                .salesCurrentYear || 0);

            transformedData.find(
              (item2) => item2.name === item.name
            ).salesCompareYear =
              (item.salesCompareYear || 0) +
              (transformedData.find((item2) => item2.name === item.name)
                .salesCompareYear || 0);
          } else {
            transformedData.push(item);
          }
        });
        transformedData.forEach((item) => {
          if (item.salesCompareYear === 0) item.compare = "";
          else
            item.compare = (
              (item.salesCurrentYear / item.salesCompareYear) *
              100
            ).toFixed(2);
        });
        setDataTicketsByCountry(transformedData);
      });
  }, [compareYear, filteredTicket, filteredCarnavalDay, filteredCurrency]);

  return (
    <>
      <Filters>
        <TextField
          id="compareYear"
          label="Compare Year"
          variant="outlined"
          name="compareYear"
          onChange={(e) => setCompareYear(e.target.value)}
          value={compareYear}
          placeholder=""
          type="number"
        />
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="product-filter"
            options={ticketsNames}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={filteredTicket}
            onChange={(e, newValue) => setFilteredTicket(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Product" placeholder="Search" />
            )}
            renderTags={(list) => {
              let displayList = list.map((item) => item).join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {displayList}
                </span>
              );
            }}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        filteredTicket.length !== ticketsNames.length
                          ? setFilteredTicket(ticketsNames)
                          : setFilteredTicket([]);
                      }}
                      label="Select all"
                      htmlFor="select-all-products-checkbox"
                      control={
                        <Checkbox
                          id="select-all-products-checkbox"
                          checked={
                            filteredTicket.length === ticketsNames.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            id="carnaval-day-filter"
            options={carnavalDays}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            value={filteredCarnavalDay}
            onChange={(e, newValue) => setFilteredCarnavalDay(newValue)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Carnaval Day"
                placeholder="Search"
              />
            )}
            renderTags={(list) => {
              let displayList = list.map((item) => item).join(", ");
              return (
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {displayList}
                </span>
              );
            }}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        filteredCarnavalDay.length !== carnavalDays.length
                          ? setFilteredCarnavalDay(carnavalDays)
                          : setFilteredCarnavalDay([]);
                      }}
                      label="Select all"
                      htmlFor="select-all-carnavalDays-checkbox"
                      control={
                        <Checkbox
                          id="select-all-carnavalDays-checkbox"
                          checked={
                            filteredCarnavalDay.length === carnavalDays.length
                          }
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="currency-label">Currency</InputLabel>
          <Select
            labelId="currency-label"
            id="currency"
            name="currency"
            label="currency"
            value={filteredCurrency}
            onChange={(e) => setFilteredCurrency(e.target.value)}
            multiple
            MenuProps={MenuProps}
            renderValue={(selected) => selected.join(", ")}
            input={<OutlinedInput label="Tag" />}
          >
            {currencies.map((currency) => {
              return (
                <MenuItem value={currency} key={currency}>
                  <Checkbox checked={filteredCurrency.indexOf(currency) > -1} />
                  <ListItemText primary={currency} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Filters>
      <h1>TICKETS SOLD PER COUNTRY</h1>
      <Table>
        <thead>
          <tr>
            <th>Country</th>
            <th>Sales in {currentYear}</th>
            <th>Sales in {compareYear}</th>
            <th>
              Change ({currentYear}/{compareYear}){" "}
            </th>
          </tr>
        </thead>
        <tbody>
          {dataTicketsByCountry.map((country) => (
            <tr key={country.name}>
              <td>{country.name}</td>
              <td>{country.salesCurrentYear}</td>
              <td>{country.salesCompareYear}</td>
              <td>{country.compare ? `${country.compare}%` : ""}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default TicketsPerCountry;
