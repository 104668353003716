import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Sidebar from "../../components/Sidebar";
import {
  Main,
  Title,
  Table,
  Tables,
  InnerContent,
} from "./inventorysummaryfloripa";

import {
  CloseFilter,
  Content,
  DownloadExcelWrapper,
  Filter,
  FilterTitle,
} from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import InventoryDetailsFloripa from "../InventoryDetailsFloripa";
import { API_URL } from "../../utils/env";

const convertDate = (date) => {
  if (!date) return "";
  date = date.split("/");
  const day = date[0];
  const month = date[1];
  const year = date[2];

  return new Date(`${year}-${month}-${day}`);
};

const InventorySummaryFloripa = () => {
  const {
    sidebarClosed,
    setSidebarClosed,
    selectedFiltersInventorySummaryFloripa: selectedFilters,
    setSelectedFiltersInventorySummaryFloripa: setSelectedFilters,
    inventorySummaryIsFilteredFloripa: inventorySummaryIsFiltered,
    setInventorySummaryIsFilteredFloripa: setInventorySummaryIsFiltered,
  } = useContext(StoreContext);

  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [activeFilter, setActiveFilter] = useState(false);
  const [filtersOptions, setFiltersOptions] = useState([]);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState(0);
  const [selectedLines, setSelectedLines] = useState([]);

  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const columnsNames = [
    {
      code: "sector",
      name: "Sector",
    },
    {
      code: "quantity",
      name: "Quantity",
    },
  ];
  const [dateFilters, setDateFilters] = useState({
    registeredDateFormated: {
      start: "0001-01-02",
      end: "9999-12-08",
    },
  });

  const table1Ref = useRef(null);

  const openFilter = useCallback(
    (filter) => {
      setSearch("");
      setActiveFilter(filter);
      let options = [];
      tickets.forEach((ticket) => {
        if (options.indexOf(ticket[filter]) === -1) {
          let valid = true;
          selectedFilters.forEach((selectedFilter) => {
            if (
              selectedFilter.selecteds.indexOf(
                ticket[selectedFilter.attributeName]
              ) === -1 &&
              selectedFilter.attributeName !== filter
            )
              valid = false;
          });
          if (valid) options.push(ticket[filter]);
        }
      });
      options = options.sort((a, b) => (a > b ? 1 : -1));
      setFiltersOptions(options);
    },
    [tickets, setActiveFilter, selectedFilters]
  );

  const searchFilters = useCallback(
    (e) => {
      const search = e.target.value;
      const filter = activeFilter;
      setSearch(search);
      let options = [];
      tickets.forEach((ticket) => {
        if (options.indexOf(ticket[filter]) === -1)
          options.push(ticket[filter]);
      });
      options = options.filter((a) =>
        a ? a.toLowerCase().includes(search.toLowerCase()) : false
      );
      options = options.sort((a, b) => (a > b ? 1 : -1));
      setFiltersOptions(options);
    },
    [activeFilter, tickets]
  );

  const closeFilter = useCallback(() => {
    setActiveFilter(false);
  }, []);

  const changeFilter = useCallback(
    (e, attribute, option) => {
      let newFilters = selectedFilters;
      if (e.target.checked) {
        if (newFilters.find((item) => item.attributeName === attribute)) {
          newFilters
            .find((item) => item.attributeName === attribute)
            .selecteds.push(option);
        } else {
          newFilters.push({ attributeName: attribute, selecteds: [option] });
        }
      } else {
        newFilters
          .find((item) => item.attributeName === attribute)
          .selecteds.splice(
            newFilters
              .find((item) => item.attributeName === attribute)
              .selecteds.indexOf(option),
            1
          );
      }

      setSelectedFilters(newFilters);
      setReset(Math.random());
    },
    [selectedFilters, activeFilter]
  );

  const changeDateFilter = useCallback(
    (e, filter, id) => {
      let newDateFilters = dateFilters;
      newDateFilters[filter][id] = e.target.value;
      setDateFilters(newDateFilters);
      setReset(Math.random());
    },
    [dateFilters]
  );

  const checkFilterIsActive = useCallback(
    (option) => {
      if (
        selectedFilters.find((item) => item.attributeName === activeFilter) &&
        selectedFilters
          .find((item) => item.attributeName === activeFilter)
          .selecteds.indexOf(option) !== -1
      )
        return true;
      else return false;
    },
    [activeFilter, selectedFilters, reset]
  );

  const checkIfColumnIsFiltered = useCallback(
    (column) => {
      let filters = [];

      tickets.forEach((ticket) => {
        if (filters.indexOf(ticket[column]) === -1)
          filters.push(ticket[column]);
      });

      const total = filters.length;

      if (
        !selectedFilters.find((filter) => filter.attributeName === column) ||
        !selectedFilters.find((filter) => filter.attributeName === column)
          .selecteds
      )
        return false;

      return (
        selectedFilters.find((filter) => filter.attributeName === column)
          .selecteds.length !== total
      );
    },
    [selectedFilters, tickets]
  );

  const selectAll = useCallback(() => {
    let newFilters = selectedFilters;

    let filter = newFilters.find(
      (filter) => filter.attributeName === activeFilter
    );

    tickets.forEach((ticket) => {
      if (filter.selecteds.indexOf(ticket[activeFilter]) === -1)
        filter.selecteds.push(ticket[activeFilter]);
    });

    newFilters.find(
      (filter) => filter.attributeName === activeFilter
    ).selecteds = filter.selecteds;

    setSelectedFilters(newFilters);
    setReset(Math.random());
  }, [activeFilter, selectedFilters]);

  const clearAll = useCallback(() => {
    let newFilters = selectedFilters;
    newFilters.find(
      (filter) => filter.attributeName === activeFilter
    ).selecteds = [];
    setSelectedFilters(newFilters);
    setReset(Math.random());
  }, [activeFilter, selectedFilters]);

  const toggleSelectedLine = useCallback(
    (ticketId) => {
      let newSelectedLines = selectedLines;
      if (newSelectedLines.indexOf(ticketId) === -1)
        newSelectedLines.push(ticketId);
      else newSelectedLines.splice(newSelectedLines.indexOf(ticketId), 1);
      setSelectedLines(newSelectedLines);
      setReset(Math.random());
    },
    [selectedLines]
  );

  useEffect(() => {
    fetch(`${API_URL}floripa/tickets/inventory-summary.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        setTickets(response);
        setFilteredTickets(response);

        if (!inventorySummaryIsFiltered) {
          if (response.length === 0 || columnsNames.length === 0) return;
          setInventorySummaryIsFiltered(true);
          //Seleciona todos os filtros possíveis
          let filters = [];

          columnsNames.forEach((attribute) => {
            response.forEach((ticket) => {
              if (
                filters.find((item) => item.attributeName === attribute.code)
              ) {
                if (
                  filters
                    .find((item) => item.attributeName === attribute.code)
                    .selecteds.indexOf(ticket[attribute.code]) === -1
                )
                  filters
                    .find((item) => item.attributeName === attribute.code)
                    .selecteds.push(ticket[attribute.code]);
              } else {
                filters.push({
                  attributeName: attribute.code,
                  selecteds: [ticket[attribute.code]],
                });
              }
            });
          });

          setSelectedFilters(filters);
        }
      });
  }, []);

  useEffect(() => {
    setSidebarClosed(true);
  }, []);

  //Atualiza os tickets filtrados
  useEffect(() => {
    const filteredItems = tickets.filter((ticket) => {
      let ok = true;
      selectedFilters.forEach((filter) => {
        if (filter.selecteds.indexOf(ticket[filter.attributeName]) === -1) {
          ok = false;
          return;
        }
      });
      if (
        convertDate(ticket.registeredDateFormated) <
          new Date(dateFilters.registeredDateFormated.start) ||
        convertDate(ticket.registeredDateFormated) >
          new Date(dateFilters.registeredDateFormated.end)
      ) {
        ok = false;
      }

      return ok;
    });
    setFilteredTickets(filteredItems);
  }, [selectedFilters, reset, tickets, dateFilters]);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <Title>Inventory</Title>
        <InnerContent>
          <DownloadExcelWrapper>
            <DownloadTableExcel
              filename="Inventory-Resume"
              sheet="inventory-resume"
              currentTableRef={table1Ref.current}
            >
              <Button variant="outlined" style={{ marginBottom: "20px" }}>
                Export to excel
              </Button>
            </DownloadTableExcel>
          </DownloadExcelWrapper>
          <Tables>
            <Table ref={table1Ref}>
              <thead>
                <tr>
                  {columnsNames.map((column) => {
                    return (
                      <th
                        onClick={() => openFilter(column.code)}
                        className={`${
                          checkIfColumnIsFiltered(column.code) ? "active" : ""
                        }`}
                        key={column.code}
                      >
                        {column.name}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {filteredTickets.map((ticket) => {
                  return (
                    <tr
                      key={ticket.sector}
                      className={
                        selectedLines.indexOf(ticket.sector) !== -1
                          ? "selected"
                          : ""
                      }
                      onClick={() => toggleSelectedLine(ticket.sector)}
                    >
                      <td>
                        {ticket.sector} - {ticket.date}
                      </td>
                      <td>{ticket.quantity}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Tables>
          <Filter opened={(activeFilter !== false).toString()}>
            <FilterTitle>
              Filtering{" "}
              {activeFilter &&
                columnsNames.find((filter) => activeFilter === filter.code)
                  .name}
            </FilterTitle>
            <CloseFilter onClick={() => closeFilter()} />
            {activeFilter === "registeredDateFormated" ? (
              <div className="date-filter">
                <TextField
                  id={`start-${activeFilter}`}
                  label="Start Date"
                  variant="outlined"
                  name={`start-${activeFilter}`}
                  type="date"
                  value={dateFilters.registeredDateFormated.start}
                  onChange={(e) => changeDateFilter(e, activeFilter, "start")}
                />
                <TextField
                  id={`end-${activeFilter}`}
                  label="End Date"
                  variant="outlined"
                  name={`end-${activeFilter}`}
                  type="date"
                  value={dateFilters.registeredDateFormated.end}
                  onChange={(e) => changeDateFilter(e, activeFilter, "end")}
                />
              </div>
            ) : (
              <ul status={reset}>
                <div className="filters-actions">
                  <span onClick={selectAll}>Select All</span>
                  <span onClick={clearAll}>Clear All</span>
                </div>
                <div className="filters-search">
                  <TextField
                    id="search"
                    label="Search"
                    variant="outlined"
                    name="search-filter"
                    value={search}
                    onChange={searchFilters}
                  />
                </div>
                <div className="filters-options">
                  {filtersOptions.map((option) => {
                    return (
                      <li key={option}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id={option}
                                name={option}
                                onChange={(e) =>
                                  changeFilter(e, activeFilter, option)
                                }
                                checked={checkFilterIsActive(option)}
                              />
                            }
                            label={
                              activeFilter === "fulfilled"
                                ? option === "1"
                                  ? "Yes"
                                  : "No"
                                : option
                            }
                          />
                        </FormGroup>
                      </li>
                    );
                  })}
                </div>
              </ul>
            )}
          </Filter>
          <InventoryDetailsFloripa />
        </InnerContent>
      </Content>
    </Main>
  );
};

export default InventorySummaryFloripa;
