import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";

import Sidebar from "../../components/Sidebar";
import { Main, SubTitle, Table, Tables, Title, SubTr } from "./summarytransfer";

import { Content, DownloadExcelWrapper } from "../../utils/stylesbase";

import StoreContext from "../../components/Store/Context";
import { Button } from "@mui/material";
import { API_URL } from "../../utils/env";

const SummaryTransfer = () => {
  const [tickets, setTickets] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [reset, setReset] = useState(0);

  const tableRef = useRef(null);

  const { sidebarClosed, setSidebarClosed } = useContext(StoreContext);

  const toggleSelectedLine = useCallback(
    (tourId) => {
      let newSelectedLines = selectedLines;
      if (newSelectedLines.indexOf(tourId) === -1)
        newSelectedLines.push(tourId);
      else newSelectedLines.splice(newSelectedLines.indexOf(tourId), 1);
      setSelectedLines(newSelectedLines);
      setReset(Math.random());
    },
    [selectedLines]
  );

  useEffect(() => {
    setSidebarClosed(true);
    fetch(`${API_URL}transfers/summary.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedTickets = [];
        transformedTickets.push({ transferType: "total" });
        response.forEach((ticket) => {
          if (
            (!ticket.transferType && !ticket.leftTransferType) ||
            (!ticket.ticketsDate && !ticket.leftDate)
          )
            return;
          let sectorAlreadyInserted = transformedTickets.find(
            (item) =>
              item.transferType === ticket.transferType ||
              item.transferType === ticket.leftTransferType
          );
          if (!sectorAlreadyInserted) {
            let newItem = {};
            newItem["transferType"] =
              ticket.transferType || ticket.leftTransferType;
            let ticketDate = ticket.ticketsDate || ticket.leftDate;
            newItem[ticketDate] = {
              bought: parseInt(ticket.bought_quantity || 0),
              sold: parseInt(ticket.sold_quantity || 0),
              inventory:
                parseInt(ticket.bought_quantity || 0) -
                parseInt(ticket.sold_quantity || 0),
            };
            transformedTickets.push(newItem);
          } else {
            let ticketDate = ticket.ticketsDate || ticket.leftDate;
            sectorAlreadyInserted[ticketDate] = {
              bought: parseInt(ticket.bought_quantity || 0),
              sold: parseInt(ticket.sold_quantity || 0),
              inventory:
                parseInt(ticket.bought_quantity || 0) -
                parseInt(ticket.sold_quantity || 0),
            };
          }

          let ticketDate = ticket.ticketsDate || ticket.leftDate;

          let totalSector = transformedTickets.find(
            (item) => item.transferType === "total"
          );

          if (totalSector[ticketDate]) {
            totalSector[ticketDate].bought += parseInt(
              ticket.bought_quantity || 0
            );
            totalSector[ticketDate].sold += parseInt(ticket.sold_quantity || 0);
            totalSector[ticketDate].inventory +=
              parseInt(ticket.bought_quantity || 0) -
              parseInt(ticket.sold_quantity || 0);
          } else {
            totalSector[ticketDate] = {
              bought: parseInt(ticket.bought_quantity || 0),
              sold: parseInt(ticket.sold_quantity || 0),
              inventory:
                parseInt(ticket.bought_quantity || 0) -
                parseInt(ticket.sold_quantity || 0),
            };
          }
        });

        console.log("transformedTickets: ", transformedTickets);

        transformedTickets = transformedTickets.sort((a, b) =>
          a.transferType === "total" ? 1 : -1
        );

        transformedTickets.forEach((ticket) => {
          ticket.total = {};
          Object.entries(ticket).forEach((date) => {
            if (date[0] !== "transferType" && date[0] !== "total") {
              if (ticket.total.bought !== undefined) {
                ticket.total.bought += date[1].bought;
                ticket.total.sold += date[1].sold;
                ticket.total.inventory += date[1].inventory;
              } else {
                ticket.total.bought = date[1].bought;
                ticket.total.sold = date[1].sold;
                ticket.total.inventory = date[1].inventory;
              }
            }
          });
        });

        let orderedTickets = transformedTickets;

        setTickets(orderedTickets);
      });

    fetch(`${API_URL}dropdown-data/dates.php`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((response) => {
        let transformedData = [];
        response.forEach((date) => transformedData.push(date.value));
        transformedData.push("total");
        setDates(transformedData);
      });
  }, []);

  return (
    <Main>
      <Sidebar />
      <Content sidebarclosed={sidebarClosed.toString()}>
        <SubTitle>Tickets</SubTitle>
        <Title>Summary</Title>
        <DownloadExcelWrapper>
          <DownloadTableExcel
            filename="Summary"
            sheet="summary"
            currentTableRef={tableRef.current}
          >
            <Button variant="outlined" style={{ marginBottom: "20px" }}>
              {" "}
              Export to excel{" "}
            </Button>
          </DownloadTableExcel>
        </DownloadExcelWrapper>
        <Tables>
          <Table ref={tableRef}>
            <thead>
              <tr>
                <th>Ticket</th>
                {dates.map((date) => (
                  <th key={date} colSpan="3">
                    {date}
                  </th>
                ))}
              </tr>
              <SubTr>
                <th></th>
                {dates.map((date) => (
                  <>
                    <th key={`${date}-BG`} className="titles">
                      BOUGHT
                    </th>
                    <th key={`${date}-SD`} className="titles">
                      SOLD
                    </th>
                    <th key={`${date}-IV`} className="titles">
                      TOTAL
                    </th>
                  </>
                ))}
              </SubTr>
            </thead>
            <tbody>
              {tickets.map((ticket) => {
                return (
                  <SubTr
                    key={`${ticket.transferType}-${reset}`}
                    bigFont
                    total={ticket.transferType === "total" ? "true" : "false"}
                    className={`${
                      selectedLines.indexOf(ticket.transferType) !== -1
                        ? "selected"
                        : ""
                    } `}
                    onClick={() => toggleSelectedLine(ticket.transferType)}
                  >
                    <td className="sector">{ticket.transferType}</td>
                    {dates.map((date) => {
                      return (
                        <>
                          <td key={`${date}-BG-Value`}>
                            {ticket[date] && ticket[date].bought}
                          </td>
                          <td key={`${date}-SD-Value`}>
                            {ticket[date] && ticket[date].sold}
                          </td>
                          <td
                            key={`${date}-IV-Value`}
                            style={{
                              color:
                                ticket[date] && ticket[date].inventory < 0
                                  ? "red"
                                  : "",
                            }}
                          >
                            {ticket[date] && ticket[date].inventory}
                          </td>
                        </>
                      );
                    })}
                  </SubTr>
                );
              })}
            </tbody>
          </Table>
        </Tables>
      </Content>
    </Main>
  );
};

export default SummaryTransfer;
